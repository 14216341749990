@import 'styles/theme';

.LandingNavigationComponent {
  margin-block: 30px;

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    transition: background-color 0.5s ease-in-out;

    &.none {
      background-color: transparent;
    }

    &.dark {
      background-color: theme($colors, 'black');
    }

    &.light {
      background-color: theme($colors, 'white');
    }
  }

  .wrapper {
    grid-column: 2 / -2;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: theme($spacings, 30);

    @include from('md') {
      grid-column: 1 / -1;
    }
  }

  .anchor {
    position: relative;
    text-align: center;
    transition: color 0.25s ease-out;

    &.active {
      transition: color 0.25s ease-out;
      &:after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1px;
      bottom: -3px;
      left: 0;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }

    &.dark {
      color: rgba(theme($colors, 'black'), 0.7);

      &.active {
        color: rgba(theme($colors, 'black'), 1);
      }

      &:after {
        background-color: rgba(theme($colors, 'black'), 1);
      }

      @media (hover: hover) {
        &:hover {
          color: rgba(theme($colors, 'black'), 1);
        }
      }
    }

    &.light {
      color: rgba(theme($colors, 'white'), 0.7);

      &.active {
        color: rgba(theme($colors, 'white'), 1);
      }

      &:after {
        background-color: rgba(theme($colors, 'white'), 1);
      }

      @media (hover: hover) {
        &:hover {
          color: rgba(theme($colors, 'white'), 1);
        }
      }
    }

    @media (hover: hover) {
      &:hover {
        transition: color 0.25s ease-out;
        &:after {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }
    }
  }
}
