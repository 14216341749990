// Text transforms
.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

// Font styles/weights
.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

// Alignments
.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

// Letter spacings
.spaced-1 {
  letter-spacing: 1px;
}

.spaced-2 {
  letter-spacing: 2px;
}
