@import './reset.scss';
@import './theme';

@import './cookie-pro.scss';
@import './parcellab.scss';

@font-face {
  font-family: 'Haffer';
  src: url('https://cdn.shopify.com/s/files/1/0604/7409/7823/files/HafferSQ-Regular_81f0b5ae-c405-4d22-ae43-2fcf7faa12fe.woff2')
    format('woff2');
  font-weight: 400;
}

:root {
  --scroll-bar: 0px;
  --scroll-bar-grid: 0px;
  --vh100: 100vh;
  --banner-height: 0px;
  --banner-displayed: 0;
  --banner-market-displayed: 0;
  --zendesk-icon: block;
  --background-color: white;
}

html,
body {
  width: 100%;
  color: theme($colors, 'cod-gray');
  font-family: theme($fontFamilies, 'HafferSQ');
  font-weight: theme($fontWeights, 'regular');
  font-size: theme($fontSizes, 12);
  line-height: 1.4;
  overscroll-behavior-y: none;
  @include fontSmoothed();
  scroll-behavior: smooth;
  background-color: var(--background-color);
}

body {
  min-height: stableVh(100);
}

em {
  font-style: italic;
}

.grecaptcha-badge {
  visibility: hidden;
}

.sb-show-main.sb-main-centered #root {
  padding: 0;
  box-sizing: border-box;
}

body {
  &.loading {
    cursor: wait;
  }

  &.preload * {
    transition: none !important;
  }
}

strong {
  font-weight: theme($fontWeights, 'bold');
}

*,
a,
button,
input {
  &:focus {
    outline: theme($colors, 'black') solid thin;
    // outline-offset: 0px;
  }

  *:focus:not(:focus-visible) {
    outline: none;
  }

  &:focus-visible {
    outline: theme($colors, 'black') solid thin;
    // outline-offset: 5px;
  }
}

iframe#launcher {
  margin-bottom: 70px !important;

  @include from('lg') {
    margin-bottom: 20px !important;
  }
}

#pwrd-by-pl {
  display: none;
}

#chat-button-button {
  bottom: var(--banner-offset, 0px) !important;
}
