@import 'styles/theme';

.FooterLinks {
  .panelHead {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: theme($spacings, 15) 0;

    .icon {
      width: 10px;
    }
  }

  .title {
    + .links {
      margin-top: theme($spacings, 20);

      @include from('md') {
        margin-top: theme($spacings, 15);
      }
    }
  }

  .links {
    display: flex;
    flex-direction: column;
    gap: theme($spacings, 10);

    .inlineLink {
      text-align: left;
    }

    .link {
      display: flex;
      align-items: center;

      .icon {
        width: 10px;
        margin-top: 1px;
        margin-right: theme($spacings, 5);
      }
    }
  }

  .ratio {
    @include applyPercentRatios(1);
  }
}
