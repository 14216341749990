@import 'styles/theme';

.SquaredCta {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color 0.2s linear, color 0.2s linear,
    border-color 0.2s linear;
  min-width: 135px;
  padding: theme($spacings, 13) theme($spacings, 20);
  color: theme($colors, 'black');
  border-width: 1px;
  border-style: solid;
  border-color: transparent;

  &:disabled {
    opacity: 0.35;
  }

  &.fullWidth {
    display: block;
    width: 100%;
  }

  &.withBackground {
    &.blackTheme {
      background-color: theme($colors, 'black');
      color: theme($colors, 'white');

      path {
        stroke: theme($colors, 'write');
      }

      span {
        text-decoration-color: theme($colors, 'white');
      }

      &.hovered {
        background-color: theme($colors, 'white');
        color: theme($colors, 'black');

        path {
          stroke: theme($colors, 'black');
        }

        span {
          text-decoration-color: theme($colors, 'black');
        }
      }
    }

    &.whiteTheme {
      background-color: theme($colors, 'white');
      color: theme($colors, 'black');

      path {
        stroke: theme($colors, 'black');
      }

      span {
        text-decoration-color: theme($colors, 'black');
      }

      &.hovered {
        background-color: theme($colors, 'black');
        color: theme($colors, 'white');

        path {
          stroke: theme($colors, 'white');
        }

        span {
          text-decoration-color: theme($colors, 'white');
        }
      }
    }
  }

  &.withBorder {
    &.blackTheme {
      border-color: theme($colors, 'black');

      &:not(.withBackground) {
        &.hovered {
          background-color: theme($colors, 'black');
          color: theme($colors, 'white');

          path {
            stroke: theme($colors, 'white');
          }

          span {
            text-decoration-color: theme($colors, 'white');
          }
        }
      }
    }

    &.whiteTheme {
      border-color: theme($colors, 'alto');

      &:not(.withBackground) {
        &.hovered {
          background-color: theme($colors, 'white');
          color: theme($colors, 'black');

          path {
            stroke: theme($colors, 'black');
          }

          span {
            text-decoration-color: theme($colors, 'black');
          }
        }
      }
    }
  }

  .childrenContent {
    flex: 1;

    &.isLoading {
      opacity: 0;
    }
  }

  &.loading {
    pointer-events: none;
  }

  path {
    transition: stroke 0.2s linear;
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
  }

  .line {
    position: absolute;
    bottom: 0px;
  }
}
