@import 'styles/theme';

.Alerts {
  position: fixed;
  bottom: 20px;
  right: 20px;
  // need to be higher the panelManager
  z-index: 2147483649;
  left: 20px;

  @include from('md') {
    left: unset;
  }

  & > div {
    & + div {
      margin-top: theme($spacings, 10);
    }
  }
}

.Alert {
  position: relative;
  padding: theme($spacings, 30);
  background: theme($colors, 'white');
  border: 1px solid theme($colors, 'gallery');
  box-shadow: 0px 0px 15px 3px rgba(theme($colors, 'black'), 0.05);
}

.CloseIcon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.text {
  margin-top: theme($spacings, 10);
}
