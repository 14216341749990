@import 'styles/theme';

.PanelWrapper {
  z-index: 20;
  width: 100%;
  height: 100%;
  background-color: theme($colors, 'pampas');

  .closeIcon {
    z-index: 4;
    position: absolute;
    @include applyColumnWidth('padding-left', 0.5, $mobile);
    @include applyColumnWidth('padding-right', 0.5, $mobile);
    padding-top: theme($spacings, 12);
    padding-bottom: theme($spacings, 12);
    line-height: 0;
    cursor: pointer;
    top: 8px;

    @include applyColumnWidth('right', 0.5, $mobile);

    @include from('lg') {
      top: 15px;
      @include applyColumnWidth('right', 0.5, $desktop);
    }
  }
  .close {
    display: inline-flex;
    align-items: center;
    box-sizing: border-box;
    width: 15px;
  }

  .content {
    height: 100%;
  }

  .childrenWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: stableVh(100);

    @include from('lg') {
      height: vh(100);
    }

    &.withoutBorder {
      padding-top: 0;

      &::before {
        content: none;
      }

      .childrenContent {
        padding-top: theme($spacings, 50);

        @include from('lg') {
          padding-top: theme($navHeight, 'mobile');
        }

        @include from('lg') {
          padding-top: theme($navHeight, 'desktop');
        }
      }
    }

    .childrenContent {
      min-height: stableVh(100);
      overflow-y: auto;

      @include customScrollBarTheme();
    }

    .children {
      display: flex;
      flex-direction: column;
      height: stableVh(100);

      box-sizing: border-box;
    }
  }

  &.enterFromTop {
    .childrenWrapper {
      @include from('lg') {
        height: auto;
      }
    }
  }
}
