@import 'styles/theme';

/**
* Class name format:
* --> -{mobile-size}-{desktop-size}
*/
.cta {
  /** 
  * REBRAND 2024/2025
  */
  &-11-haffer {
    @include font-base(11, null, 'Haffer', 1);
    letter-spacing: 1px;
  }

  &-12-haffer {
    @include font-base(12, null, 'Haffer', 1);
    letter-spacing: 1px;
  }
}
