@import 'styles/theme';

.LogOutButton {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;

  .icon {
    margin-left: theme($spacings, 6);
    width: 10px;
  }
}

.Account {
  .heroName {
    display: flex;
    justify-content: space-between;

    @include from('md') {
      @include applyColumnWidth('width', 6, $desktop);
    }
  }

  .container {
    grid-column: 2 / -2;

    &.orders {
      margin-top: theme($spacings, 20);

      @include from('md') {
        grid-column: 8 / -2;
        margin-top: 0;
      }
    }

    &.account_informations {
      margin-top: theme($spacings, 20);

      @include from('md') {
        grid-column: 8 / -6;
        margin-top: 0;
      }
    }

    &.address_book {
      margin-top: theme($spacings, 20);

      @include from('md') {
        grid-column: 8 / -3;
        margin-top: 0;
      }
    }

    .title {
      display: none;

      @include from('md') {
        display: block;
        margin-bottom: theme($spacings, 30);
      }
    }
  }
}
