@use 'sass:math';
@import 'styles/theme';

.NavigationDesktop {
  position: relative;
  width: 100%;

  // Themes
  &.theme-white {
    @include fontSmoothed();

    .rightLinks {
      .link {
        .count {
          background: theme($colors, 'white');
          transition: background 0.2s linear;
        }
      }
    }
  }

  .backgroundTopBar,
  .backgroundPanel {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: center top;
    background: theme($colors, 'white');
  }

  .background {
    z-index: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: vh(100);
    background: rgba(theme($colors, 'black'), 0.5);
    pointer-events: none;
  }

  .content {
    z-index: 1;
    position: relative;
    width: 100%;
    background-color: transparent;
    transition: 0.3s border-color;
    border-bottom: 1px solid transparent;

    &.isOpen {
      border-color: theme($colors, 'gallery');
    }
  }

  .close {
    z-index: 1;
    position: absolute;
    top: theme($spacings, 10);
    right: calc(
      #{getGridColumnMaxWidth(1, $desktop)} - #{theme($spacings, 10)}
    );
    padding: theme($spacings, 15);
    cursor: pointer;

    .closeIcon {
      width: 20px;
    }
  }

  .ratio {
    @include applyPercentRatios(math.div(391, 350));
  }

  .links {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-column: 2/11;

    .link {
      position: relative;
      padding-top: theme($spacings, 30);
      padding-bottom: theme($spacings, 30);
      padding-left: theme($spacings, 8);
      padding-right: theme($spacings, 8);
      text-transform: uppercase;
      line-height: 0;
      word-break: normal;

      // Fix to prevent issue on hover down link loose focus
      &.hovered {
        &::after {
          z-index: 10;
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 60%;
          left: 0;
          padding: 0 70px;
          margin: 0 -70px;
        }
      }

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }
  }

  .logoWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: span 4;
    padding-left: theme($spacings, 16);
    padding-right: theme($spacings, 16);
    box-sizing: border-box;

    .logo {
      max-width: 100%;
    }
  }

  .rightLinks {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    grid-column: -2/-11;

    .link {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: theme($spacings, 16);
      transition: color 0.2s linear, border-color 0.2s linear;

      .cartContainer {
        position: relative;
        line-height: 0;
      }

      .count {
        position: absolute;
        bottom: 0;
        left: 100%;
        background: theme($colors, 'black');
        width: 16px;
        height: 16px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
      }
    }
  }

  .NavigationPanel {
    z-index: 1;
    position: relative;
    max-width: theme($max, 'width');
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    .panelContent {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      box-sizing: border-box;
      padding-top: theme($spacings, 20);
      padding-bottom: theme($spacings, 20);
      @include applyColumnWidth('padding-left', 1, $desktop);
      @include applyColumnWidth('padding-right', 1, $desktop);
    }

    .imageItems {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: center;
    }

    .columns {
      display: flex;
      flex-direction: row;

      .column {
        display: flex;
        flex-direction: column;
        @include applyColumnWidth('width', 4, $desktop);
        @include applyColumnWidth('margin-right', 1, $desktop);

        .section {
          margin-top: theme($spacings, 60);

          &:first-child {
            margin-top: 0;
          }
        }

        .sectionTitle {
          margin-bottom: theme($spacings, 30);
        }

        .sectionLinks {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .sectionLink {
            margin-top: theme($spacings, 10);
            text-align: left;
            text-transform: uppercase;

            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  .ImageItem {
    position: relative;
    display: block;
    @include applyColumnWidth('width', 5, $desktop);

    .images {
      position: relative;
      display: block;
    }

    .imageHover {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &.left {
      margin-right: theme($spacings, 20);
    }
  }
}
