@import 'styles/theme';

.Banner {
  position: relative;
  background-color: theme($colors, 'black');
  overflow: hidden;
  padding: theme($spacings, 5) 0;
  display: flex;
  align-items: center;
  z-index: 99;
  height: theme($navHeight, 'banner');
  box-sizing: border-box;

  // Added gradient masks when the screen is above the max grid width to avoid the raw cut
  @include from('max-grid-width') {
    &:after,
    &:before {
      position: absolute;
      content: '';
      width: 20px;
      height: 100%;
      z-index: 99;
    }

    &:before {
      background: linear-gradient(90deg, rgba(0, 0, 0, 1), transparent);
    }

    &:after {
      right: 0;
      background: linear-gradient(-90deg, rgba(0, 0, 0, 1), transparent);
    }
  }

  .slider {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;

    grid-column: 1/-1;
  }

  .textWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    user-select: none;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    @include fontSmoothed();
    @include applyColumns(
      (
        'propertyName': 'padding-right',
        'desktop': 1.5,
        'mobile': 2,
      )
    );

    @include applyColumns(
      (
        'propertyName': 'padding-left',
        'desktop': 1.5,
        'mobile': 2,
      )
    );
  }

  .textContainer {
    display: block;

    &.hasOnlyOneText {
      @include from('l') {
        width: 101vw;
      }
    }
  }

  .text {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    margin-inline: theme($spacings, 20);

    @include from('l') {
      margin-inline: theme($spacings, 70);
    }
  }
}
