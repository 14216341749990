@import 'styles/theme';

.banner {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  .header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid theme($colors, 'gallery');
    gap: theme($spacings, 10);

    @include from('lg') {
      gap: theme($spacings, 20);
    }

    .title {
      @include applyColumnWidth('margin-left', 1, $mobile);
      padding: theme($spacings, 22) 0;
    }

    .closeButton {
      width: 12px;
      position: relative;
      top: 1px;
      @include applyColumnWidth('margin-right', 1, $mobile);
    }
  }

  .descriptionText {
    @include applyColumnWidth('margin-inline', 1, $mobile);
    padding: theme($spacings, 24) 0;
  }

  .contentSection {
    @include applyColumnWidth('margin-inline', 1, $mobile);
    display: grid;
    row-gap: theme($spacings, 12);
  }

  .ctaContainer {
    margin-top: auto;
    @include applyColumnWidth('margin-inline', 1, $mobile);
    margin-bottom: theme($spacings, 24);

    .ctaButton {
      width: 100%;
    }
  }
}

@include from('lg') {
  .banner {
    .header {
      border-bottom: 0;

      .title {
        @include applyColumnWidth('margin-left', 1, $desktop);
        padding: theme($spacings, 30) 0;
      }

      .closeButton {
        width: 14px;
        @include applyColumnWidth('margin-right', 1, $desktop);
      }
    }

    .descriptionText {
      @include applyColumnWidth('margin-inline', 1, $desktop);
      padding: theme($spacings, 20) 0;
    }

    .contentSection {
      @include applyColumnWidth('margin-inline', 1, $desktop);
    }

    .ctaContainer {
      @include applyColumnWidth('margin-inline', 1, $desktop);
      margin-bottom: theme($spacings, 30);
    }
  }
}
