@use 'sass:math';
@import 'styles/theme';

.NavigationMobile {
  position: relative;
  background-color: transparent;
  transition: 0.15s background-color, 0.15s border-color;
  border-bottom: 1px solid transparent;

  // Themes
  &.theme-white {
    @include fontSmoothed();

    .content {
      .count {
        background-color: theme($colors, 'white');
        transition: background-color 0.2s linear;
      }
    }
  }

  &.hasScrolled {
    background-color: theme($colors, 'white');
  }

  &.isOpen {
    background-color: theme($colors, 'white');
    border-color: rgba(theme($colors, 'black'), 0.1);
  }

  .ratio {
    @include applyPercentRatios(math.div(160, 220));
  }

  .content {
    grid-column: 2 / -2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: theme($spacings, 20);
    padding-bottom: theme($spacings, 20);

    .left,
    .right {
      height: 18px;
      @include applyColumnWidth('width', 5, $mobile);
    }

    .left {
      text-align: left;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .burger {
      width: 100%;
      height: 100%;
      text-align: left;
      justify-content: flex-start;
    }

    .open,
    .close {
      padding: theme($spacings, 10);
      margin-left: -10px;
      margin-top: -10px;
      line-height: 0;
      width: 16px;
      height: 16px;
    }

    .right {
      display: flex;
      justify-content: flex-end;
    }

    .cartContainer {
      display: inline-flex;
      align-items: center;
    }

    .iconContainer {
      position: relative;
      line-height: 0;
    }

    .topIcon {
      display: block;
      line-height: 0;
      width: 18px;

      .storeLocator {
        height: 22px;
        // As the icon is 18x22 we need to pull the icon a bit up
        margin-top: -#{theme($spacings, 2)};
      }

      svg {
        width: 18px;
      }
    }
  }

  .panel {
    background: theme($colors, 'white');
    position: absolute;
    top: theme($navHeight, 'mobile');
    left: 0;
    width: 100%;
    height: calc(#{vh(100)} - #{theme($navHeight, 'mobileWithBanner')});
    border-top: 1px solid theme($colors, 'gallery');
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .panelContent {
      overflow: auto;
      @include customScrollBarTheme();
    }

    .mainLink {
      cursor: pointer;
      position: relative;
      text-transform: uppercase;
      padding-top: theme($spacings, 20);
      padding-bottom: theme($spacings, 20);
      @include applyColumnWidth('margin-left', 1, $mobile);
      @include applyColumnWidth('margin-right', 1, $mobile);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid theme($colors, 'gallery');

      &.back {
        justify-content: flex-start;

        .icon {
          margin-right: theme($spacings, 10);
        }
      }

      .icon {
        width: 12px;
        height: 12px;
        fill: theme($colors, 'black');
      }
    }

    .bottomIcons {
      border-top: 1px solid theme($colors, 'gallery');
      height: 60px;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      box-sizing: border-box;

      &.hasShop {
        .link {
          width: 50%;
        }
      }

      .link {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        height: 100%;

        &:last-child {
          border-left: 1px solid theme($colors, 'gallery');
        }

        .icon {
          margin-right: theme($spacings, 10);
        }
      }
    }
  }

  .NavigationSubPanel {
    .columns {
      display: flex;
      flex-direction: column;
      @include applyColumnWidth('margin-right', 1, $mobile);
      @include applyColumnWidth('margin-left', 1, $mobile);

      .column {
        display: flex;
        flex-direction: column;
        margin-top: theme($spacings, 40);

        &:first-child {
          margin-top: theme($spacings, 20);
        }

        .section {
          width: 100%;

          &:not(:first-child) {
            margin-top: theme($spacings, 50);
          }
        }

        .sectionTitle {
          margin-bottom: theme($spacings, 20);
        }

        .sectionLinks {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .sectionLink {
            margin-top: theme($spacings, 10);
            text-transform: uppercase;

            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }

    .imageItems {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;
      text-align: center;
      overflow: hidden;
      margin-top: theme($spacings, 40);
      padding-bottom: theme($spacings, 40);

      @include applyColumnWidth('padding-right', 1, $mobile);
      @include applyColumnWidth('padding-left', 1, $mobile);

      .ImageItem {
        width: calc(50% - #{getGridColumnWidth(0.25, $mobile)});

        .image {
          height: auto;
        }

        .text {
          display: block;
          margin-top: theme($spacings, 10);
        }
      }
    }
  }
}
