@import 'styles/text-presets/typography.module.scss';
@import 'styles/theme';

.FormTemplate {
  form {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: theme($spacings, 10);

    @include from('md') {
      grid-template-columns: repeat(2, 1fr);
      column-gap: theme($spacings, 10);
      row-gap: theme($spacings, 10);
    }
  }

  div,
  button {
    grid-column: span 1;

    @include from('md') {
      grid-column: span 2;
    }
  }

  span[role='alert'] {
    grid-column: span 2;
  }

  input {
    width: 100%;
    border-radius: 0;
    box-sizing: border-box;
    padding: theme($spacings, 15) theme($spacings, 10);
    border: 1px solid theme($colors, 'alto');
    background-color: transparent;
    background-clip: padding-box;
    transition: border 0.2s ease;

    &::placeholder {
      color: currentColor;
    }
  }

  input[type='radio'] {
    width: auto;
    padding: 0;
    accent-color: theme($colors, 'black');
    cursor: pointer;

    + label {
      position: relative;
      cursor: pointer;
      margin-bottom: 0;
      margin-left: theme($spacings, 5);
      top: -3px;
      letter-spacing: 1px;
      text-transform: unset;
    }
  }

  select {
    width: 100%;
    cursor: pointer;
    padding: theme($spacings, 15) theme($spacings, 10);
    appearance: none;
    border: none;
    box-sizing: border-box;
    border: solid 1px theme($colors, 'alto');
    transition: border 0.2s ease;
  }

  input,
  select {
    @include font-base(12, null, 'Haffer', 1);
    letter-spacing: 1px;
  }

  input[type='checkbox'] + label {
    display: inline;
    position: relative;
    top: -3px;
    margin-bottom: 0;
    margin-left: theme($spacings, 5);
    cursor: pointer;
    text-transform: unset;
    letter-spacing: 1px;
  }

  input[type='checkbox'] {
    position: relative;
    width: 12px;
    height: 12px;
    border: 1px solid theme($colors, 'dove-gray');
    box-sizing: border-box;
    appearance: none;
    border-radius: unset;

    &:checked {
      background-color: theme($colors, 'black');
      border-color: theme($colors, 'black');

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50%;
        height: 50%;
        background: url("data:image/svg+xml,%3Csvg viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.004 2.688 3.19 4.874 6.837.5' stroke='%23FEFBF1' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")
          no-repeat center;
        transform: translate(-50%, -50%);
      }
    }
  }

  input[type='date'] {
    padding-top: 13px;
    padding-bottom: 14px;
  }

  label,
  legend {
    margin-bottom: theme($spacings, 4);
    display: inline-flex;
    color: theme($colors, 'dove-gray');
    text-transform: uppercase;
    @include font-base(10, null, 'Haffer', 1);
    letter-spacing: 2px;

    &[data-input-focused='true'] {
      color: theme($colors, 'black');
    }
  }

  label[for='phone'] + div {
    display: flex;
    flex-wrap: wrap;

    select {
      width: 35%;

      & + div,
      & + span {
        width: 65%;
      }
    }

    span {
      width: 65%;
    }

    input[type='tel'] {
      flex: 1;
    }
  }

  label[for='acceptsPrivacyPolicy'] a {
    text-decoration: underline;
  }

  span[role='alert'] {
    display: block;
    margin-top: theme($spacings, 5);
    width: 100%;
    color: theme($colors, 'pomegranate');
    @include font-base(10, null, 'Haffer', 1);
    letter-spacing: 1px;
  }

  input[name='phone'] {
    border-left: 0;
  }
}
