@import 'styles/theme';

/**
* Class name format:
* --> -{mobile-size}-{desktop-size}
*/
.title {
  /*
  * REBRAND 2024
  */
  &-11-haffer {
    @include font-base(11, null, 'Haffer', 1.5);
    letter-spacing: 1px;
  }

  &-11-12-haffer {
    @include font-base(11, 12, 'Haffer', 1.5);
    letter-spacing: 1px;
  }

  &-12-haffer {
    @include font-base(12, null, 'Haffer', 1);
    letter-spacing: 1px;
  }

  &-14-haffer {
    @include font-base(14, null, 'Haffer', 1);
    letter-spacing: 1px;
  }

  &-12-14-haffer {
    @include font-base(12, 14, 'Haffer', 1.2);
    letter-spacing: 1px;
  }

  &-12-18-haffer {
    @include font-base(12, 18, 'Haffer', 1.2);
    letter-spacing: 1px;
  }

  &-14-16-haffer {
    @include font-base(14, 16, 'Haffer', 1.2);
    letter-spacing: 1px;
  }

  &-14-18-haffer {
    @include font-base(14, 18, 'Haffer', 1.2);
    letter-spacing: 1px;
  }

  &-16-haffer {
    @include font-base(16, null, 'Haffer', 1.2);
    letter-spacing: 1px;
  }

  &-16-18-haffer {
    @include font-base(16, 18, 'Haffer', 1.2);
    letter-spacing: 1px;
  }

  &-16-22-haffer {
    @include font-base(16, 22, 'Haffer', 1.2);
    letter-spacing: 1px;
  }

  &-18-haffer {
    @include font-base(18, null, 'Haffer', 1.2);
    letter-spacing: 1px;
  }

  &-18-22-haffer {
    @include font-base(18, 22, 'Haffer', 1.2);
    @include font-base(18, null, 'Haffer', 1);
    letter-spacing: 1px;
  }

  &-22-haffer {
    @include font-base(22, null, 'Haffer', 1.2);
    letter-spacing: 1px;
  }

  &-22-16-haffer {
    @include font-base(22, 16, 'Haffer', 1.2);
    letter-spacing: 1px;
  }

  &-22-30-haffer {
    @include font-base(22, 30, 'Haffer', 1.2);
    letter-spacing: 1px;
  }
}
