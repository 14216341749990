@import 'styles/theme';

.inputContainer {
  position: relative;
}

.input {
  border-color: theme($colors, 'alto');

  padding: theme($spacings, 15) theme($spacings, 60) theme($spacings, 16)
    theme($spacings, 15);

  &:focus {
    border-color: theme($colors, 'dove-gray');
  }

  &::placeholder {
    text-transform: uppercase;
  }
}

.submitButton {
  position: absolute;
  right: 0;
  top: 22px;
  transform: translate3d(0, -50%, 0);
  padding: 0 theme($spacings, 15);
  cursor: pointer;
  width: auto;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .icon {
    width: 10px;
    height: 10px;
  }

  .spinner {
    width: theme($spacings, 15);
  }
}

.gdpr {
  margin-top: theme($spacings, 20);
}

.gdprLegals {
  margin-top: theme($spacings, 5);
}

.result {
  margin-top: theme($spacings, 10);
}
