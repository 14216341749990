@import 'styles/theme';

.LandingNavigation {
  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: transparent;
    z-index: 5;
  }

  .mainNavigation {
    transition: background-color 0.5s ease-in-out;

    &.none {
      background-color: transparent;
    }

    &.dark {
      background-color: theme($colors, 'black');
    }

    &.light {
      background-color: theme($colors, 'white');
    }
  }
}
