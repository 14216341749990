@import 'styles/theme';

.PopInManager {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  box-sizing: border-box;

  @include customScrollBarTheme();

  &.vertical-top {
    align-items: flex-start;
  }

  &.vertical-center {
    align-items: center;
  }

  &.vertical-bottom {
    align-items: flex-end;
  }

  &.horizontal-left {
    justify-content: flex-start;
  }

  &.horizontal-center {
    justify-content: center;
  }

  &.horizontal-right {
    justify-content: flex-end;
  }

  &.show {
    .background {
      opacity: 1;
    }
  }

  > * {
    pointer-events: auto;
  }

  .background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: 0.3s opacity;
  }
}
