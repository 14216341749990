@import 'styles/theme';

/**
* Class name format:
* --> -{mobile-size}-{desktop-size}
*/
.text {
  &-11-haffer {
    @include font-base(11, null, 'Haffer', 1.5);
    letter-spacing: 1px;
  }

  &-12-haffer {
    @include font-base(12, null, 'Haffer', 1.5);
    letter-spacing: 1px;
  }

  &-11-12-haffer {
    @include font-base(11, 12, 'Haffer', 1.5);
    letter-spacing: 1px;
  }
}
